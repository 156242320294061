;(async () => {
  const url = new URL(window.location)
  const IS_DEV = url.hostname === 'el-dev.sendinitaly.com'
  const API_URL = `https://api${IS_DEV ? '-dev' : ''}.sendinitaly.com`

  if (url.pathname.startsWith('/u/')) {
    document.title = 'Annulla iscrizione alla Newsletter'
    const url = new URL(window.location)
    const token = url.pathname.split('/')[2]
    const email = jwt_decode(token).u[0]
    document.querySelector('#email').textContent = email
    const unsubscribeButton = document.querySelector('#unsubscribe')
    const responseMessage = document.querySelector('#response-message')
    unsubscribeButton.addEventListener('click', async () => {
      try {
        unsubscribeButton.disabled = true
        const response = await fetch(`${API_URL}/v1/contacts/_/unsubscribe`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({token}),
        })
        if (response.status === 204) {
          responseMessage.textContent = 'Iscrizione annullata con successo'
          responseMessage.classList.add('text-green-600')
        } else if (response.status === 200) {
          const message = (await response.json()).message
          if (message === 'Contact already blacklisted') {
            responseMessage.textContent = 'Iscrizione già annullata'
            responseMessage.classList.add('text-blue-600')
          }
        } else {
          throw new Error('Invalid response')
        }
      } catch (e) {
        responseMessage.textContent = "Errore durante l'annullamento dell'iscrizione"
        responseMessage.classList.add('text-red-600')
      } finally {
        document.querySelector('#step-1').hidden = true
        document.querySelector('#step-2').hidden = false
      }
    })
    document.querySelector('#u-view').hidden = false
    document.querySelector('#step-1').hidden = false
  } else if (url.pathname.startsWith('/m/')) {
    document.title = 'Visualizza contenuto'

    const url = new URL(window.location)
    const token = url.pathname.split('/')[2]
    const content = await (
      await fetch(`${API_URL}/v1/campaigns/_/mirror`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({token}),
      })
    ).json()
    if (content?.html) {
      const iframe = document.createElement('iframe')
      iframe.srcdoc = content.html
      iframe.classList.add('w-full', 'h-screen', 'border-0')
      document.body.append(iframe)
    }
  }
})()
